<template>
    <div class="grid">
        <div class="col-12">
            <Loading v-if="loading" />
            <div class="card">
                <div>
                    <h5 class="titulo">Empresa</h5>
                    <div class="botoes_topo" v-if="this.modo=='visualizar'">
                        <Button label="Editar" class="p-button-primary"/>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-1">
                        <label>Cód</label>
                        <InputText v-model="empresa_carregada.id" type="text" readonly/>
                    </div>
                    <div class="col-5">
                        <label>Nome Fantasia</label>
                        <InputText v-model="empresa_carregada.nome_fantasia" type="text" />
                    </div>
                    <div class="col-6">
                        <label>Razão Social</label>
                        <InputText v-model="empresa_carregada.razao_social" />
                    </div>
                </div>
                <div class="grid">
                    <div class="col-4">
                        <label>CNPJ</label>
                        <InputText  type="text" v-model="empresa_carregada.cnpj" @blur="validarCNPJ"/>
                        <InlineMessage v-if="!state.cnpjValido">CNPJ inválido!</InlineMessage>
                    </div>
                    <div class="col-5">
                        <label>E-mail</label>
                        <InputText v-model="empresa_carregada.email"/>
                    </div>

                    <div class="col-3">
                        <label>Área de Atuação</label>
                        <select class="select_empresa" v-model="empresa_carregada.area_atuacao_id">
                            <option v-for="area_atuacao in state.areas_atuacao" :key="area_atuacao.id" :value="area_atuacao.id">{{area_atuacao.nome}}</option>
                        </select>
                        {{ areas_atuacao }}
                    </div>
                </div>
                <h5 class="titulo_pedidos">Endereço</h5>
                <div class="grid">
                    <div class="col-9">
                        <label>Logradouro</label>
                        <InputText type="text" v-model="empresa_carregada.logradouro" />
                    </div>
                    <div class="col-3">
                        <label>Número</label>
                        <InputText v-model="empresa_carregada.numero"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label>Bairro</label>
                        <InputText  type="text" v-model="empresa_carregada.bairro"/>
                    </div>
                    <div class="col-6">
                        <label>Complemento</label>
                        <InputText v-model="empresa_carregada.complemento" />
                    </div>
                </div>
                <h5 class="titulo_pedidos">Logo</h5>
                <div class="grid">
                    <div class="col-4">
                        <div class="inputFile" style="margin-top:10px; height:33px !important">
                            <div style="position:absolute; left: 50%; margin-left: -60px; margin-top: 7px">
                                Selecionar Arquivo
                            </div>
                            <div style="border: #000 solid 1px">
                                <input type="file" style="width:100%" @change="alterarLogo">
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <img :src="state.logo_url" class="imagem_logo">
                    </div>
                </div>

                <div class="div_botoes">
                    <Button label="Salvar" class="p-button-primary btn_success"  @click="salvarEmpresa()" />
                    <Button label="Cancelar" class="p-button-primary btn_cancel" @click="cancelar()"/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { reactive } from 'vue';
import { cnpj } from 'cpf-cnpj-validator';
export default {
    setup(){
        const state = reactive({
            logo_url:'',
            logo_file: '',
            areas_atuacao: [],
            cnpj: '',
            cnpjValido: false,
        })
        return{
            state
        }
    },
    data() {
        return {
            representante_id: this.$storage.getStorageSync("user").representante_id,
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_carregada:{
                nome_fantasia:'',
                razao_social:'',
                cnpj:'',
                email:'',
            //    areas_atuacao:[],
                logo_url:''
            },
            modo:'visualizar'
        }
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
        this.carregarAreaAtuacao()
        

        if (this.$router.currentRoute.value.params.id != undefined) {
            this.carregarEmpresa()
        } else {
            this.modo = 'editar'
        }
    },
    methods:{
        alterarLogo(event){
            const element = event.target.files[0];
            this.state.logo_url = URL.createObjectURL(element);
            this.state.logo_file = element
            console.log(this.state.logo_url)
        },
        cancelar() {
            console.log("cancelar");  
        },
        carregarEmpresa() {
            this.loading = true;
            if (this.$router.currentRoute.value.params.id != undefined) {
                this.api.carregarEmpresa(this.token, this.$router.currentRoute.value.params.id).then(data => {
                    this.empresa_carregada = data.data.resposta
                    this.state.logo_url = data.data.logo
                    this.area_atuacao_id = data.data.resposta.area_atuacao_id
                    this.loading = false; 
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }else{ 
                this.modo = "editar"
            }
            this.loading = false;
        },
        salvarEmpresa(){
            var formAtualizar = new FormData();
            formAtualizar.append("nome_fantasia",this.empresa_carregada.nome_fantasia);
            formAtualizar.append("razao_social",this.empresa_carregada.razao_social)
            formAtualizar.append("cnpj",this.empresa_carregada.cnpj)
            formAtualizar.append("email",this.empresa_carregada.email)
            formAtualizar.append("telefone",this.empresa_carregada.telefone)
            formAtualizar.append("area_atuacao_id",this.empresa_carregada.area_atuacao_id)
            formAtualizar.append("logradouro",this.empresa_carregada.logradouro)
            formAtualizar.append("numero",this.empresa_carregada.numero)
            formAtualizar.append("bairro",this.empresa_carregada.bairro)
            formAtualizar.append("cep",this.empresa_carregada.cep)
            formAtualizar.append("logo", this.state.logo_file)  
            formAtualizar.append("representante_id", this.representante_id)  
            

            if (this.$router.currentRoute.value.params.id != undefined) {
                this.api.atualizarEmpresa(this.token, formAtualizar, this.$router.currentRoute.value.params.id).then(data => {
                    if (data.data.verifica_erro == false) {
                      //  this.$router.push('/');
                      console.log('aquii')
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            } else { 
                this.api.salvarEmpresa(this.token, formAtualizar).then(data => {
                    if (data.data.verifica_erro == false) {
                        console.log('aquii')
                      //  this.$router.push('/');
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }
            if(this.$router.currentRoute.value.params.id == this.empresa_id) {
                this.api.carregarEmpresa(this.token, this.empresa_id).then(data => {
                    this.$storage.setStorageSync("empresa", data.data)

                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });               
            //    
            }

        },
        validarCNPJ() {
            //console.log(cnpj.isValid(this.empresa_carregada.cnpj))
            this.state.cnpjValido = cnpj.isValid(this.empresa_carregada.cnpj);
        },
        carregarAreaAtuacao() {
            this.api.listarAreasAtuacao().then(data => {
                this.state.areas_atuacao = data.data
                this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        }
    },
    components: { Loading }
}
</script>

<style>
label{
    font-size: 12pt;
    font-weight: bold;
}
.titulo{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
input{
    margin: 0px;
    width:100%
}
.div_botoes{
    margin-bottom:10px;
    width:100%;
    text-align:center;
}
.btn_cancel{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
    margin-left:10px;
    width: 15rem;
}
.btn_cancel:hover{
    background-color:#9d9d9d !important; 
    border:#9d9d9d solid 1px !important;
}
.btn_success{
    width: 15rem;
}
.select_empresa{
    width:100%;
    border: 1px solid #ced4da;
    padding:0.75rem 0.75rem;
    background-color:#fff;
    border-radius: 6px;
    color:#495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.inputFile{
    width: 100%;
    height: 60px;
    position:relative;
    overflow: hidden;
    border-bottom: #000 solid 1px;
}

.inputFile input{
    opacity: 0;
    filter: alpha(opacity=0);
    right:0;
    padding-top: 5px;
    z-index: 2;
    height: 80px;
    font-size:100px;
}
.imagem_logo{
    height:80px;
}
</style>